.about-content {
  background: white;
  &__left {
    margin-bottom: $grid-gutter-width*2;
    @media (min-width: $screen-md-min) {
      margin-bottom: 0;
    }
  }
  .divider-half {
    margin-bottom: $grid-gutter-width;
  }
}

.about-gallery {
  position: relative;
  padding-right: $grid-gutter-width/2;
  img {
    max-width: 100%;
    height: auto;
  }
  .slider {
    box-shadow: $grid-gutter-width/2 $grid-gutter-width/2 0px $brand-primary;
  }
  .slider-nav {
    position: relative;
    top: auto;
    margin: $grid-gutter-width 0 0 $grid-gutter-width;
    @include clearfix;
  }
  .slider-prev,
  .slider-next {
    float: left;
    margin: 0 $padding-xs-horizontal 0 0;
    width: 60px;
    height: 60px;
    border: none;
    background: $brand-primary;
    color: white;
    border-radius: 0;
    font-size: 38px;
    &:hover {
      background: darken($brand-primary, 5%);
    }
  }
}

.team-members {
  background: white;
  padding-bottom: $grid-gutter-width*3;
  &__col {
    padding-top: $grid-gutter-width/2;
    padding-bottom: $grid-gutter-width/2;
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      &:nth-child(2n+1) {
        clear: both;
      }
    }
    @media (min-width: $screen-md-min) {
      &:nth-child(3n+1) {
        clear: both;
      }
    }
  }
  &__item {
    position: relative;
    max-width: 361px;
    margin: 0 auto;
    background: url(/assets/base/imgs/team_mask-2.png) -9999px -9999px no-repeat; // Preload hover bg
    &__bg {
      position: relative;
      z-index: 1;
      height: 0;
      padding-bottom: 162%;
      background: url(/assets/base/imgs/team_mask-2.png) center center no-repeat;
      background-size: cover;
      &:before {
        display: block;
        width: 100%;
        content: '';
        height: 0;
        padding-bottom: 100%;
      }
    }
    &__text {
      min-height: $font-size-h3*3;
      margin: $grid-gutter-width/2 0;
      padding: 0 30px;
      line-height: $font-size-h3;
    }
    .field-picture {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      img {
        width: 100%;
      }
    }
    .field-name {
      font-size: $font-size-h4;
      font-weight: bold;
    }
    .field-role {
      font-size: $font-size-h5;
    }
    .field-social {
      opacity: 1;
      transition: all 0.25s ease;
      li {
        margin: 0;
        border-right: solid 1px $text-color;
        &:last-child {
          border-right: none;
        }
      }
      a {
        margin: 0 $padding-xs-horizontal;
        font-size: $font-size-large;
        color: $text-color;
      }
    }
    @media (min-width: (361px + $grid-gutter-width)) and (max-width: $screen-sm-max) {
      &__text {
        min-height: $font-size-h2*3;
        margin: $grid-gutter-width 0 $grid-gutter-width/2 0;
        padding: 0 40px;
        line-height: $font-size-h2;
      }
      .field-name {
        font-size: $font-size-h3;
      }
      .field-role {
        font-size: $font-size-h4;
      }
    }
    @media (min-width: $screen-lg-min) {
      &__bg {
        background: url(/assets/base/imgs/team_mask-1.png) center center no-repeat;
        &:hover {
          background-image: url(/assets/base/imgs/team_mask-2.png);
          .field-social {
            opacity: 1;
          }
        }
      }
      &__text {
        min-height: $font-size-h2*3;
        margin: $grid-gutter-width 0 $grid-gutter-width/2 0;
        padding: 0 40px;
        line-height: $font-size-h2;
      }
      .field-name {
        font-size: $font-size-h3;
      }
      .field-role {
        font-size: $font-size-h4;
      }
      .field-social {
        opacity: 0;
      }
    }
  }
}
