$icon-font-path: "/assets/base/theme/fonts";
$fa-font-path: "/assets/base/theme/assets/font-awesome-4.7.0/fonts";

// @import "../theme/css/bootstrap.min"; // TODO: download the bootstrap-sass and use it insted
@import "../theme/assets/font-awesome-4.7.0/scss/font-awesome";
// @import "../theme/assets/jquery-ui-1.12.1/jquery-ui.min";
@import "../theme/assets/slick-1.6.0/slick.scss";
// @import "../theme/assets/chosen-1.6.2/chosen.min";
@import "../theme/css/nouislider.min";
@import "../theme/css/style.scss";
@import "../theme/css/responsive";


// body {
//   overflow-y: scroll;
// }


.navbar-brand img {
    max-width: 152px;
}

.header-default .nav.navbar-nav .logo-menu-item a {
  padding: 15px 20px;
}
.main-menu-wrap {
  .logo-menu-item {
    display: none;
    a {
      padding: 15px 20px;
    }
    img {
      height: 30px;
    }
  }
  &.fixed .logo-menu-item {
    @media screen and (min-width: $screen-md-min) {
      display: block;
    }
  }
}

.header-default {
  .header-item {
    @media screen and (max-width: $screen-sm-max) {
      width: 50% !important;
    }
  }
  // .header-item:nth-last-child(2) {
  //   @media (min-width: $screen-md-min) {
  //     border-left: none;
  //   }
  // }
  .header-item-text a {
    color: #464646;
  }
  .member-actions .button {
    &:focus {
      color: white;
      text-decoration: inherit;
    }
  }
}
.subheader-slider.subheader-slider--fixed-bg .slide {
  background-attachment: fixed !important;
}

.button.grey-2 {
  background-color: $gray-light;
}
.button.grey-2:hover {
  background-color: lighten($gray-light, 5%);
}

.widget {
  h2, h3 {
    margin-bottom: $grid-gutter-width;
    span {
      display:block;
      margin-bottom: $grid-gutter-width/2;
    }
  }
}

.form-block.recaptcha {
  margin-bottom: $grid-gutter-width;
}

.cta.newsletter {
  h3 {
    margin-top: ($grid-gutter-width/2 + $padding-large-vertical);
  }
  .error {
    font-weight: bold;
  }
  .success-msg {
    margin-top: ($grid-gutter-width/2 + $padding-large-vertical);
    line-height: $font-size-h3;
  }
}

.g-recaptcha,
.g-recaptcha-alt {
  > div {
    margin: 0 auto;
  }
}

.modal form {
  input {
    border: solid 1px $input-border;
  }
}

.close {
  line-height: 19px;
  font-size: 30px;
  font-weight: 600;
}

#footer {
  .contacts-btn {
    margin-top: $grid-gutter-width;
    padding: $padding-large-vertical $grid-gutter-width;
    border-radius: 40px;
    font-weight: bold;
    color: #323746;
    i {
      margin-left: $grid-gutter-width/2;
    }
  }
}
.footer-link-list {
  font-size: $font-size-large;
  a {
    color: #8e95ac;
    &:hover,
    &:focus {
      color: lighten(#8e95ac, 20%);
    }
  }
}
.footer-map {
  margin-bottom: $grid-gutter-width;
  iframe {
    width: 100%;
    max-width: 100%;
    height: 200px;
  }
}
.footer-skype-button {
  padding-left: 5px;
  margin-top: 10px;
  img {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  p {
    margin: 0;
  }
}


.privacy-policy-bar {
  position: fixed;
  z-index: 9999;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: $grid-gutter-width*2 0;
  background: $gray-darker;
  background: rgba($gray-darker, 0.95);
  // border-top: solid 1px $gray-darker;
  // color: $text-color;
  color: white;
  text-align: center;

  a {
    text-decoration: underline;
  }
  button {
    // vertical-align: bottom;
    margin: $grid-gutter-width/2 0 0 $grid-gutter-width/2;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }
}
