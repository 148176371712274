.subheader.google-maps.horizontal,
.subheader.google-maps.horizontal #map-canvas {
  height: 400px;
}
.subheader.subheader-slider {
  background: transparent;
}

.property-tag.label {
  line-height: inherit;
}

.property-listing-header h1 {
  margin: 0;
  white-space: normal;
  font-size: 25px;
}
.property-single-item .property-header h1 {
  display: inline-block;
  width: auto;
  white-space: normal;
  font-size: 25px;
}

.property-single-item .property-header.property-header-slider {
  bottom: 0;
}

.property-gallery-variable-width {
  height: 450px;
  overflow: hidden;
  &__slides {
    height: 450px;
  }
  .slick-slide {
    outline: none;
  }
  &__slide {
    display: flex;
    align-items: center;
    height: 450px;
    padding: 0 $padding-small-horizontal/2;
  }
  &__slide-inner {
    position: relative;
    &:before {
      content: '';
      background: rgba(255, 255, 255, .60);
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      opacity: 1;
      transition: opacity .25s ease;
    }
  }
  .slick-current &__slide-inner:before {
    opacity: 0;
  }
  img {
    width: auto;
    height: auto;
    max-height: 450px;
  }
  .slick-dots li button {
    &:before {
      font-size: 10px;
    }
  }
}

.property-gallery {
  .slide-inner {
    width: 100%;
    height: 422px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    max-height: 422px;
  }
}

.other-properties {
  .property-title {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
    h5 {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.pagination .button {
  cursor: pointer;
  &[disabled] {
    cursor: not-allowed;
  }
}


.filter-item__area {
  .row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .col-xs-6 {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.filter__clear-search {
  margin-top: 10px;
}


.widget.property-detail-filter {
  padding: 5%;
  background: white;
  .widget-content.box {
    padding: 0;
  }
  // ---
  input,
  select,
  textarea,
  .chosen-container .chosen-single,
  .chosen-container .chosen-drop {
    border:1px solid rgba(0, 0, 0, 0.15);
  }
  .chosen-container .chosen-results {
    padding-top:10px;
  }
}

.widget-sidebar {
  margin-bottom: $grid-gutter-width*1.5;
  padding-bottom: 0;
  border: none;
}

.widget.widget-sidebar h4 {
  font-size: $font-size-h2;
  font-weight: 400;
}

.widget.additional-services {
  img {
    border-radius: 50%;
  }
}

.widget.property-detail-enquiry,
.widget.property-detail-find-property,
.property-list-find-property {
  background: $brand-primary;
  text-align: center;
  padding: $grid-gutter-width*2 $grid-gutter-width;
  margin-bottom: 0;
  h4 {
    font-weight: bold;
    color: white;
  }
  .btn {
    padding: $padding-large-vertical $grid-gutter-width;
    border-radius: 40px;
    font-weight: bold;
    background: white;
    color: #3a464e;
    &:hover,
    &:focus {
      background: $gray-lighter;
    }
    i {
      margin-left: $grid-gutter-width/2;
    }
  }
}

.widget.property-detail-find-property {
  margin-bottom: $grid-gutter-width*1.5;
}

.property-list-find-property {
  @media (min-width: $screen-md-min) {
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 338px;
  }
  @media (min-width: $screen-lg-min) {
    min-height: 389px;
  }
}

.widget.property-detail-actions {
  padding: $grid-gutter-width/2 $padding-large-horizontal;
  border: solid 2px $brand-primary;
  background: white;
  text-align: center;
  .btn {
    padding: $padding-large-vertical $grid-gutter-width;
    border-radius: 40px;
    font-weight: bold;
    background: white;
    color: #3a464e;
    &:hover,
    &:focus {
      background: $gray-lighter;
    }
    i {
      margin-left: 5px;
    }
  }
}

.amenities-list {
  columns: 3;
  -webkit-columns: 3;
  -moz-columns: 3;
}

#enquiry-modal {
  input, textarea {
    border: solid 1px $input-border;
  }
}
