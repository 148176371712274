@import "variables";
@import "../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";

@import "../../../../static/base/css/common";

body {
  // overflow-y: scroll;
  font-weight: 400;
}

.text-primary {
  color: $brand-primary;
}

.button-block {
    display: block;
    width: 100%;
}
.button.disabled, .button[disabled], fieldset[disabled] .button {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: .65;
}

.cta.newsletter {
  background-color: #8cc63e;
}

.property--featured {
  background: $brand-primary;
  .property-title {
    border-bottom-color: white;
    color: white;
    h4,
    a,
    .fa{
      color: white;
    }
    a:hover {
      color: mix($brand-primary, white, 25%);
    }
  }
  .property-details {
    color: white;
  }
}

.module.property-categories {
  background: white;
  .module-header {
    margin-bottom: 30px;
  }
  .property-cat {
    position: relative;
    .content {
      position: absolute;
      top: 50%;
      text-shadow: 0 0 5px $gray-darker;
    }
    h3 {
      font-size: $font-size-h1;
      font-weight: bold;
      margin: 0;
    }
    p {
      font-size: $font-size-h4;
      color: white;
      margin: 0;
    }
  }
}

.module.find-property {
  .form-block > ul {
    margin-bottom: $grid-gutter-width/1.5;
  }
  .privacy-policy {
    padding: $grid-gutter-width/2;
    margin-bottom: $grid-gutter-width/1;
    background: white;
    label {
      margin: 0;
    }
    p {
      margin: $grid-gutter-width/3 0 0 0;
    }
    a {
      font-weight: bold;
    }
  }
}

.module.filter {
  min-height: auto;
  padding-top: $grid-gutter-width;
  padding-bottom: $grid-gutter-width;
}

@import "home";
@import "property";
@import "location";
@import "about";
@import "contacts";
@import "pages";
