.subheader.subheader-slider .slide {
  h1,
  .h2,
  p {
    text-shadow: 1px 1px 2px $gray-dark;
  }
}
.subheader.subheader-slider .slider-simple-buttons .button:last-child {
  margin-left: 0;
  background-color: $brand-primary;
  box-shadow: none;
}
